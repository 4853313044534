import React from 'react';


const FormGroup: React.FC = ({ children }) => {
    return (
        <>
            <div className='form-group'>
                {children}
            </div>
        </>
    );
}

export default FormGroup;