import { useSelector } from "react-redux";
import users, { User } from "./usersReducer";

export interface AccountState {
  currentUser: User;
  balance: number;
}

const INITIAL_STATE = {
  currentUser: {
    username: "user1",
    password: "password1",
    role: "admin",
  },
  balance: 5430.34,
};

interface Action {
  type: string;
  user?: User;
  balance: number;
  amount: number;
}

function account(state: AccountState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case "SET_USER":
      return { ...state, currentUser: action.user };
    case "SET_BALANCE":
      return { ...state, balance: action.balance };
    case "DEBIT_BALANCE":
      return { ...state, balance: state.balance - action.amount };
    case "CREDIT_BALANCE":
      return { ...state, balance: state.balance + action.amount * 1 };
    default:
      return state;
  }
}

export default account;
