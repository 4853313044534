import React from 'react';

// import { Container } from './styles';

const Loading: React.FC = () => {
  return (
    <>
      <div className="loading-container">
        <div className="loading-animation">
          <div className="loading-image">
            <div className="load-wrapp">
              <div className="load-3">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
              </div>
            </div>
          </div>
          <div className="loading-text">Carregando...</div>
        </div>
      </div>
    </>
  );
};

export default Loading;
