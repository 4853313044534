import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import Row from '../../components/Row';
import StatsCard from '../../components/StatsCard';
import { Reducers } from '../../store/reducers';
import { Counter } from '../../store/reducers/countersReducer';
import axios from 'axios';
import {
  ICorruptionCase,
  ICorruptionCaseDTO,
} from '../../types/ICorruptionCase';
import Select, { MultiValue } from 'react-select';
import { getProvinceName } from '../../utils';
import FormGroup from '../../components/FormGroup';
import { provinces } from '../../types';

const COURT_TYPE = [
  '',
  'Tribunal',
  'Ministério Público',
  'Gabinete de combate a Corrupção',
];
const CorruptionCases: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'corruption-case';
  const counters = useSelector((state: Reducers) => state.counters.data);
  const dispatch = useDispatch();
  const [corruptionCases, setCorruptionCases] = useState<ICorruptionCaseDTO[]>(
    []
  );

  const [currentProvince, setCurrentProvince] = useState('');
  const [allCorruptionCases, setAllCorruptionCases] = useState<
    ICorruptionCaseDTO[]
  >([]);

  const [defendants, setDefendants] = useState([]);

  const changeProvince = (province: string) => {
    console.log(province);

    if (province === 'ALL') {
      console.log(province);

      setCorruptionCases(
        allCorruptionCases.map((corruptionCase) => corruptionCase)
      );
    } else {
      setCorruptionCases(
        allCorruptionCases.filter((corruptionCase) => {
          return corruptionCase.province === province;
        })
      );
    }
  };

  const getList = async () => {
    try {
      const result = await axios.get(`${API_URL}/${PATH}`);
      setCorruptionCases(result.data);
      setAllCorruptionCases(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDefendantList = async () => {
    try {
      const result = await axios.get(`${API_URL}/defendant`);
      setDefendants(result.data);
      dispatch({ type: 'SET_DEFENDANTS', defendants: result.data });
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const remove = async (corruptionCase: ICorruptionCaseDTO) => {
    try {
      const result = await axios.delete(
        `${API_URL}/corruption-case/${corruptionCase.id}`
      );
      console.log(result.data);
      getList();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getDefendantList();
    getList();
  }, []);

  return (
    <>
      <PageTitle>Lista de processos</PageTitle>
      {/* <Row>

                <StatsCard desc="Medicamentos Registrados" number={corruptionCases.length + ""} icon='stopwatch-20' iconColor="#fff" backgroundIconColor='#E3642F'>
                </StatsCard>

            </Row> */}
      <div className="card table-card">
        <div className="card-header">
          <h3>Lista de processos</h3>
        </div>
        <div className="card-body">
          <div className="card no-style">
            <div className="row">
              <Link to="/corruption-cases/new">
                <button className="button button-primary">
                  Adicionar novo
                </button>
              </Link>
              <FormGroup>
                <label htmlFor="">Selecione a Província</label>
                <select
                  name=""
                  id=""
                  value={currentProvince}
                  onChange={(e) => {
                    changeProvince(e.target.value);
                    setCurrentProvince(e.target.value);
                  }}
                >
                  <option value="ALL">TODAS PROVÍNCIAS</option>
                  {provinces.map((province) => (
                    <option key={province.value} value={province.value}>
                      {' '}
                      {province.name}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </div>
          </div>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Processo</th>

                  <th>Crime</th>
                  <th>Data de início</th>
                  <th>Local</th>

                  {/* <th>Arguidos</th> */}
                  <th>Qualidade das pessoas</th>

                  <th>Valor envolvido</th>

                  <th>Fase do Processo</th>

                  <th>Descrição</th>
                  <th>Província</th>

                  <th>Cidade/Distrito</th>

                  <th>Local do Processo</th>
                  <th>Acção</th>
                  <th>ID</th>
                </tr>
              </thead>
              <tbody>
                {corruptionCases.map((corruptionCase) => (
                  <tr key={corruptionCase.id}>
                    <td>{corruptionCase.process_number}</td>
                    <td>{corruptionCase.crime}</td>
                    <td>
                      {corruptionCase?.start_date
                        ? corruptionCase.start_date.substring(0, 4)
                        : ''}
                    </td>
                    <td>{COURT_TYPE[corruptionCase.court_type]}</td>

                    {/* 
                    <td>
                      {corruptionCase.defendants.map((defendant) => (
                        <span className="defendant-name">
                          {defendant.first_name + ' ' + defendant.last_name}
                        </span>
                      ))}
                    </td> */}
                    <td>{corruptionCase.defendant_type}</td>

                    <td>{corruptionCase.amount}</td>
                    <td>{corruptionCase.phase?.name}</td>

                    <td>
                      <span className="corruption-case-description">
                        {corruptionCase.description}
                      </span>
                    </td>
                    <td>{getProvinceName(corruptionCase.province)}</td>
                    <td>{corruptionCase.city}</td>
                    <td>{corruptionCase.address}</td>
                    <td style={{ gap: '5px' }}>
                      <Link
                        to={`/corruption-cases/update/${corruptionCase.id}`}
                      >
                        <button className="button button-primary">
                          Editar
                        </button>
                      </Link>
                      <button
                        className="button button-danger"
                        onClick={() => {
                          remove(corruptionCase);
                        }}
                      >
                        Apagar
                      </button>
                    </td>
                    <td>{corruptionCase.id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorruptionCases;
