import React, { useEffect, useState } from 'react';

import '../../assets/css/public.css';
import logo from '../../assets/img/Logo-cip-completo-01.png';
import axios from 'axios';
import { ICorruptionCaseDTO } from '../../types/ICorruptionCase';
import CorruptionCasesList from './CorruptionCasesListList';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { CategoryQuant, PHASE, provinces } from '../../types';
import { ICourt } from '../../types/ICourt';
import { ICategory } from '../../types/ICategory';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../store/reducers';
import Loading from '../../components/Loading';
const PublicDashboard: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'corruption-case';
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const [corruptionCases, setCorruptionCases] = useState<ICorruptionCaseDTO[]>(
    []
  );

  const YEARS = [2024, 2023, 2022, 2021, 2020, 2019];

  const [showSideBar, setShowSideBar] = useState<boolean>(true);
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [processNumber, setProcessNumber] = useState<string>('');
  const [showSearch, setShowSearch] = useState(false);
  const [step, setStep] = useState(0);
  const [category, setCategory] = useState<number>(0);
  const [court, setCourt] = useState<number>(0);
  const [currentMenu, setCurrentMenu] = useState<number>(0);
  const [province, setProvince] = useState<string>('');
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [categoryQuantArray, setCategoryQuantArray] = useState<CategoryQuant[]>(
    []
  );
  const [corruptionCase, setCorruptionCase] = useState<ICorruptionCaseDTO>({
    title: '',
    city: '',
    comments: '',
    crime: '',
    description: '',
    process_number: '',
    province: '',
    defendantIDs: [],
    defendants: [],
    phase_id: 0,
    defendant_type: '',
    amount: 0,
    category_id: 0,
    court_id: 0,
    priority: 2,
    court_type: 1,
    male_quant: 0,
    female_quant: 0,
  });

  const [showCorruptionCase, setShowCorruptionCase] = useState(false);

  const showSearchPopup = (categoryId: number) => {
    setCategory(categoryId);
    setShowSearch(true);
  };

  const selectCourt = (courtId: number) => {
    setCourt(courtId);
    setStep(2);
    // setShowSearch(false);
    // navigate(
    //   `/public/?courtId=${courtId}&province=${province}&courtId=${courtId}&categoryId=${category}`
    // );
  };

  const dispatch = useDispatch();
  const LOADING: boolean = useSelector((state: Reducers) => state.util.loading);
  const selectYear = (year: number) => {
    setStep(0);
    setShowSearch(false);
    showSideBarHandle(false);
    navigate(
      `/public/?province=${province}&categoryId=${category}&year=${year}`
    );
  };
  const getCategoryList = async () => {
    try {
      const result = await axios.get<ICategory[]>(`${API_URL}/category`);
      setCategories(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const searchByProcessNumber = async () => {
    if (processNumber.trim().length === 0) {
      return;
    }
    try {
      const result = await axios.get<ICorruptionCaseDTO>(
        `${API_URL}/${PATH}/process-number/${encodeURIComponent(
          processNumber.trim()
        )}`
      );
      console.log(result.data);
      showSideBarHandle(false);
      navigate('/public/corruption-case/' + result.data.id);
    } catch (error) {
      console.log('-----');

      navigate(
        `/public/?province=${province}&categoryId=${category}&year=2100`
      );
    }
  };

  const fetchCourtsByProvince = async (province: string) => {
    setProvince(province);
    const result = await axios.get<ICourt[]>(
      `${API_URL}/court/province/${province}`
    );
    setCourts(result.data);
    setStep(2);
  };

  const fetchQuantByCategory = async () => {
    setProvince(province);
    const result = await axios.get<CategoryQuant[]>(
      `${API_URL}/corruption-case/category/count`
    );
    setCategoryQuantArray(result.data);
  };

  const changeCategoryList = (categoryListNumber: number) => {
    setCurrentMenu(categoryListNumber);
  };

  const showSideBarHandle = (flag: boolean) => {
    const showHide = document.getElementById('show-hide');

    if (window.innerWidth < 800) {
      setShowSideBar(flag);
      if (!showSideBar) {
        if (showHide) {
          showHide.style.left = '260px';
        }
      } else {
        if (showHide) {
          showHide.style.left = '10px';
        }
      }
    }
  };

  const getCategoryQuant = (categoryId: number): number => {
    let quant = 0;

    for (let i = 0; i < categoryQuantArray.length; i++) {
      const categoryQuant = categoryQuantArray[i];
      if (categoryQuant.category_id === categoryId) {
        quant = categoryQuant._count.category_id;
      }
    }

    return quant;
  };

  async function init() {
    await fetchQuantByCategory();
    await getCategoryList();
  }
  useEffect(() => {
    // getList();
    init();
  }, []);

  return (
    <>
      {LOADING && <Loading></Loading>}
      <div className="main-section">
        <div
          className="show-hide"
          id="show-hide"
          onClick={(e) => {
            showSideBarHandle(!showSideBar);
          }}
        >
          {showSideBar ? (
            <i className="fa fa-window-close"></i>
          ) : (
            <i className="fa fa-bars"></i>
          )}
        </div>
        {showSearch && (
          <div className="popup-search">
            <div className="popup-body">
              {step === 1 && (
                <button
                  className="button button-primary"
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  <i className="fa fa-arrow-left"></i> Escolher província
                </button>
              )}

              {step === 2 && (
                <button
                  className="button button-primary"
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  <i className="fa fa-arrow-left"></i> Escolher Província
                </button>
              )}
              <div className="title">
                {(step === 0 && 'Escolha a província:') ||
                  // (step === 1 && 'Escolha o tribunal: ') ||
                  (step === 2 && 'Escolha o ano: ') ||
                  (step === 3 && 'Escolha a província:') ||
                  (step === 4 && 'Introduza o número do processo:')}
              </div>
              {step === 0 && (
                <div className="provinces">
                  {provinces.map((province, index) => (
                    <div
                      key={index}
                      className="province"
                      onClick={() => {
                        fetchCourtsByProvince(province.value);
                      }}
                    >
                      {province.name}
                    </div>
                  ))}
                </div>
              )}
              {step === 1 && (
                <div className="provinces">
                  {courts.map((court, index) => (
                    <div
                      key={index}
                      className="province"
                      onClick={() => {
                        selectCourt(court.id);
                      }}
                    >
                      {`${court.name} ${court.court_code}`}
                    </div>
                  ))}
                </div>
              )}

              {step === 2 && (
                <div className="provinces">
                  <div
                    className="province"
                    onClick={() => {
                      selectYear(0);
                    }}
                  >
                    TODOS
                  </div>
                  {YEARS.map((year, index) => (
                    <div
                      key={index}
                      className="province"
                      onClick={() => {
                        selectYear(year);
                      }}
                    >
                      {` ${year}`}
                    </div>
                  ))}
                </div>
              )}

              {step === 3 && (
                <div className="provinces">
                  {provinces.map((province, index) => (
                    <div
                      key={index}
                      className="province"
                      onClick={() => {
                        setProvince(province.value);
                        setProcessNumber('');
                        setStep(4);
                      }}
                    >
                      {province.name}
                    </div>
                  ))}
                </div>
              )}
              {step === 4 && (
                <div className="provinces">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Número do processo"
                      value={processNumber}
                      onChange={(e) => {
                        setProcessNumber(e.target.value);
                      }}
                    />
                  </div>
                </div>
              )}
              {step === 4 && (
                <button
                  className="button button-primary close"
                  onClick={() => {
                    setStep(0);
                    setShowSearch(false);
                    searchByProcessNumber();
                  }}
                >
                  Pesquisar
                </button>
              )}
              <button
                className="button button-danger close"
                onClick={() => {
                  setProcessNumber('');
                  setStep(0);
                  setShowSearch(false);
                }}
              >
                Cancelar Pesquisa
              </button>
            </div>
          </div>
        )}
        <div className="container">
          <div className="body">
            {showSideBar && (
              <div className="public-side-bar">
                <div
                  className="logo"
                  onClick={() => {
                    navigate('/');
                  }}
                >
                  <img src={logo} alt="" />
                </div>
                <div
                  className="menu-title"
                  onClick={() => {
                    navigate('/public');
                  }}
                >
                  CRIMES
                </div>
                <div className="categories">
                  <h3
                    onClick={() => {
                      changeCategoryList(1);
                    }}
                  >
                    Sector Público
                  </h3>
                  <div className="public-category">
                    {currentMenu === 1 &&
                      categories.map((category) => {
                        if (category.type === 'publico') {
                          return (
                            <div
                              key={category.id}
                              className="side-stats .selected"
                              onClick={() => {
                                showSearchPopup(category.id);
                              }}
                            >
                              <div className="icon">
                                <i className="fa fa-file"></i>
                              </div>
                              <div className="desc">
                                {category.name} ({getCategoryQuant(category.id)}
                                )
                              </div>
                              <div className="quant"></div>
                            </div>
                          );
                        }
                        return '';
                      })}
                  </div>
                  <h3
                    onClick={() => {
                      changeCategoryList(2);
                    }}
                  >
                    Sector Privado
                  </h3>

                  <div className="private-category">
                    {currentMenu === 2 &&
                      categories.map((category) => {
                        if (category.type === 'privado') {
                          return (
                            <div
                              key={category.id}
                              className="side-stats .selected"
                              onClick={() => {
                                showSearchPopup(category.id);
                              }}
                            >
                              <div className="icon">
                                <i className="fa fa-file"></i>
                              </div>
                              <div className="desc">
                                {category.name} ({getCategoryQuant(category.id)}
                                )
                              </div>
                              <div className="quant"></div>
                            </div>
                          );
                        }
                        return '';
                      })}
                  </div>
                  <h3
                    onClick={() => {
                      changeCategoryList(3);
                    }}
                  >
                    Outros
                  </h3>

                  <div className="others-category">
                    {currentMenu === 3 &&
                      categories.map((category) => {
                        if (category.type === 'outros') {
                          return (
                            <div
                              key={category.id}
                              className="side-stats .selected"
                              onClick={() => {
                                showSearchPopup(category.id);
                              }}
                            >
                              <div className="icon">
                                <i className="fa fa-file"></i>
                              </div>
                              <div className="desc">
                                {category.name} ({getCategoryQuant(category.id)}
                                )
                              </div>
                              <div className="quant"></div>
                            </div>
                          );
                        }
                        return '';
                      })}
                  </div>
                </div>
              </div>
            )}
            <div className="content-details">
              <div className="public-menu-bar">
                <div className="public-menu">
                  <div
                    className="public-menu-item"
                    onClick={() => {
                      setStep(3);
                      setShowSearch(true);
                    }}
                  >
                    <i className="fa fa-search"></i> Pesquisar Número do
                    processo
                  </div>
                </div>
              </div>
              <Outlet></Outlet>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicDashboard;
