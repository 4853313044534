import React from 'react';

// import { Container } from './styles';

const Card: React.FC = ({ children }) => {
    return (
        <div className='card'>
            {children}
        </div>
    );
}

export default Card;