import React, { useState } from 'react';
import logo from '../../assets/img/Logo-cip-completo-01.png';
import { useNavigate } from 'react-router-dom';

// import { Container } from './styles';

const Welcome: React.FC = () => {
  const navigate = useNavigate();
  const [goToLoginCount, setgoToLoginCount] = useState(0);
  return (
    <>
      <div className="welcome">
        <div className="wrapper">
          <div className="welcome-title">
            <div
              onClick={() => {
                if (goToLoginCount >= 5) {
                  navigate('/login');
                }
                setgoToLoginCount(goToLoginCount + 1);
              }}
            >
              <img src={logo} alt="" />
            </div>
            <h1>Base de dados (amostra) de Casos de Corrupção</h1>
          </div>
          <div className="message">
            <p>
              Esta base de dados é uma iniciativa do Centro de Integridade
              Pública (CIP). Tem como objectivo principal partilhar informação
              acerca da tendência da ocorrência de casos de corrupção e de
              crimes conexos em Moçambique. Procura fazer uma apresentação
              pública de uma amostra de processos em tramitação nos órgãos
              judiciários (Ministério Público e tribunais). A base de dados
              apresenta, ainda, alguns casos relacionados com o crime de
              branqueamento de capitais, constituindo-se também como uma
              amostra.
            </p>
            <br />
            <p>
              A informação processual apresentada não é exaustiva, atendendo que
              não cobre todos os casos da tipologia criminal acima referida e em
              tramitação no judiciário. Em todos os casos apresentados fica
              salvaguardado o segredo de justiça.
            </p>
          </div>
          <br />
          <div>
            <button
              className="button button-primary"
              onClick={() => {
                navigate('/public');
              }}
            >
              <h2>Aceder a base de dados</h2>
            </button>
          </div>
          <br />
          <br />
          <br />
          {/* <h2>Parceiros</h2>
          <div className="sponsors">
            <div className="sponsor"></div>
            <div className="sponsor"></div>
            <div className="sponsor"></div>
            <div className="sponsor"></div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Welcome;
