export interface Counter {
  desc: string;
  number: string;
  address: string;
}
interface Action {
  type: string;
  counter: Counter;
}

export interface CounterState {
  data: Counter[];
}

const INITAL_STATE: CounterState = {
  data: [
    {
      desc: "Mario Pedra",
      number: "1132543653654654",
      address: "Beira, 43 rrr",
    },
    {
      desc: "Paulo Kapa",
      number: "332543654654",
      address: "Maputo, 433 Laulane",
    },
    {
      desc: "Sérgio Pedra",
      number: "22254453653654654",
      address: "Gaza, 4133 xai xai",
    },
    {
      desc: "Nelson Peixe",
      number: "44354532543653654654",
      address: "Nampula, 43 Xpto",
    },
  ],
};

function counters(state = INITAL_STATE, action: Action): CounterState {
  switch (action.type) {
    case "ADD_COUNTER":
      return { ...state, data: [...state.data, action.counter] };
    case "REMOVE_COUNTER":
      return {
        ...state,
        data: state.data.filter(
          (counter) => counter.number !== action.counter.number
        ),
      };
    default:
      return state;
  }
}

export default counters;
