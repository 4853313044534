import { provinces } from '../types';

export const getProvinceName = (provinceKey: string) => {
  let name = 'NOT_FOUND';

  for (let id = 0; id < provinces.length; id++) {
    const province = provinces[id];
    if (province.value === provinceKey) {
      name = province.name;
      break;
    }
  }
  return name;
};
