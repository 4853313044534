import React, { useState } from 'react';
import Card from '../../components/card';
import FormGroup from '../../components/FormGroup';
import bciLogo from '../../assets/img/bci-logo.svg'
// import { Container } from './styles';

const BCI: React.FC = () => {
    const [confirmed, setConfirmed] = useState(false);
    const [username, setUsername] = useState("pay24");
    const [password, setPassword] = useState("pay24")
    const [passwordError, setPasswordError] = useState(false)

    const confirm = () => {
        if (username === "pay24" && password === "pay24") {
            localStorage.setItem("confirmed", "yes");
            setConfirmed(true);
            setTimeout(() => {
                // eslint-disable-next-line no-restricted-globals
                close();
            }, 2000);
        } else {
            setPasswordError(true);
        }
    }
    return (
        <>

            <Card>
                <FormGroup>
                    <img style={{ width: "100px", margin: "auto" }} src={bciLogo} alt="" />
                </FormGroup>
                {confirmed ?
                    <>
                        <FormGroup>
                            <h3 style={{ margin: "auto" }}>Pagamento feito com successo!</h3>
                            <i className='fa fa-check-circle' style={{
                                margin: "40px auto",
                                color: "green",
                                fontSize: "140px",
                            }}></i>
                        </FormGroup>
                    </> :
                    <>
                        <FormGroup>
                            <h3>Deseja fazer um pagamento de 1200 Mt?</h3>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="">Nome de utilizador:</label>
                            <input type="text" placeholder=''
                                value={username}
                                onChange={e => { setUsername(e.target.value) }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="">Código de acesso:</label>
                            <input type="password" placeholder=''
                                value={password}
                                onChange={e => { setPassword(e.target.value) }}
                            />
                        </FormGroup>
                        <FormGroup>{
                            passwordError ?
                                <span className='error'> <h3>* Credenciais incorretas</h3></span> :
                                <></>
                        }
                        </FormGroup>
                        <FormGroup>
                            <button className='button button-warning' onClick={confirm}>Confirmar</button>
                        </FormGroup>
                    </>
                }
            </Card>
        </>);
}

export default BCI;