import React, { useEffect, useState } from 'react';
import { ICorruptionCaseDTO } from '../../types/ICorruptionCase';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { IDefendant, IDefendantDTO } from '../../types/IDefendant';
import { useSelector } from 'react-redux';
import { Reducers } from '../../store/reducers';

// import { Container } from './styles';

const DefendantDetails: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'defendant';
  const { id } = useParams();
  const navigate = useNavigate();
  const [defendant, setDefendant] = useState<IDefendantDTO>();
  const [corruptionCases, setCorruptionCases] = useState<ICorruptionCaseDTO[]>(
    []
  );
  const getList = async () => {
    try {
      const result = await axios.get<IDefendantDTO>(`${API_URL}/${PATH}/${id}`);
      setDefendant(result.data);
      setCorruptionCases(
        result.data?.corruption_cases ? result.data.corruption_cases : []
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="content-details">
      <div className="col flex-1">
        <div className="_card">
          <div className="_card-header">
            Detalhes do Arguido:
            <br />
            <div>
              Nome: {defendant?.first_name} {defendant?.last_name}
            </div>
            <div>Nacionalidade: {defendant?.nationality}</div>
            <div>Província: {defendant?.province}</div>
          </div>

          <div className="_card-body">
            <div className="_card-header">Lista de casos envolvido:</div>

            <div></div>
            <div className="faze-list">
              {corruptionCases.map((corruptionCase) => {
                return (
                  <div
                    className="item"
                    key={corruptionCase.id}
                    onClick={() => {
                      // setCorruptionCase(corruptionCase);
                      // setShowCorruptionCase(true);
                      navigate('/public/corruption-case/' + corruptionCase.id);
                    }}
                  >
                    {corruptionCase.title}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefendantDetails;
