import React from 'react';
import '../../assets/css/home.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
    ChartData,
} from 'chart.js';


import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// import { Container } from './styles';
interface Props {
    options: ChartOptions;
    data: any;
    styleClasses?: string
}

const VerticalBarChart: React.FC<Props> = ({ children, options, data, styleClasses }) => {
    return (
        <Bar className={styleClasses} options={options} data={data} />

    );
}

export default VerticalBarChart;