import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import Row from '../../components/Row';
import StatsCard from '../../components/StatsCard';
import { Reducers } from '../../store/reducers';
import { Counter } from '../../store/reducers/countersReducer';
import axios from 'axios';
import { ICorruptionCase } from '../../types/ICorruptionCase';
import Select, { MultiValue } from 'react-select';
import { ICourt } from '../../types/ICourt';

const Courts: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'court';
  const counters = useSelector((state: Reducers) => state.counters.data);
  const dispatch = useDispatch();
  const [courts, setCourts] = useState<ICourt[]>([]);


  const getList = async () => {
    try {
      const result = await axios.get(`${API_URL}/${PATH}`);
      setCourts(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };



  const remove = async (court: ICourt) => {
    try {
      const result = await axios.delete(
        `${API_URL}/court/${court.id}`
      );
      console.log(result.data);
      getList();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <PageTitle>Lista de Tribunais</PageTitle>

      <div className="card table-card">
        <div className="card-header">
          <h3>Lista de Tribunais</h3>
        </div>
        <div className="card-body">
          <div className="card no-style">
            <Link to="/courts/new">
              <button className="button button-primary">Adicionar novo</button>
            </Link>
          </div>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Código do tribunal</th>

                  <th>Nome do Tribunal</th>
                  <th>Província</th>
                  <th>Acção</th>
                </tr>
              </thead>
              <tbody>
                {courts.map((court) => (
                  <tr key={court.id}>
                    <td>{court.court_code}</td>
                    <td>{court.name}</td>
                    {/* 
                    <td>
                      {court.defendants.map((defendant) => (
                        <span className="defendant-name">
                          {defendant.first_name + ' ' + defendant.last_name}
                        </span>
                      ))}
                    </td> */}
                    <td>{court.province}</td>

                    <td style={{ gap: '5px' }}>
                      <Link to={`/courts/${court.id}`}>
                        <button className="button button-primary">
                          Editar
                        </button>
                      </Link>
                      <button
                        className="button button-danger"
                        onClick={() => {
                          remove(court);
                        }}
                      >
                        Apagar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Courts;
