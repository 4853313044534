import React, { useEffect, useState } from 'react';
import {
  ICorruptionCase,
  ICorruptionCaseDTO,
} from '../../types/ICorruptionCase';
import axios from 'axios';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { IProvincesCount, PHASE } from '../../types';
import { IPhase } from '../../types/IPhase';
import { provinces } from '../../types';
import { log } from 'console';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../store/reducers';
import extenso from 'extenso';

// import { Container } from './styles';

// interface Props {
//   caseId: string;
//   showCorruptionCase: (arg0: boolean) => void;
//   corruptionCaseDetails: ICorruptionCaseDTO;
// }

const CorruptionCaseDetails: React.FC = () => {
  const [corruptionCase, setCorruptionCase] = useState<ICorruptionCaseDTO>({
    title: '',
    city: '',
    process_number: '',
    crime: '',
    comments: '',
    description: '',
    province: '',
    defendantIDs: [],
    defendants: [],
    phase_id: 0,
    defendant_type: '',
    amount: 0,
    category_id: 0,
    priority: 0,
    court_id: 0,
    start_date: undefined,
    end_date: undefined,
    court_type: 1,
    male_quant: 0,
    female_quant: 0,
  });
  const [phases, setPhases] = useState<IPhase[]>([]);

  const [quantByProvince, setQuantByProvince] = useState<IProvincesCount[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [currentMenu, setCurrentMenu] = useState(1);
  const { id } = useParams();
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'corruption-case/' + id;
  const getItem = async () => {
    try {
      dispatch({ type: 'SET_LOADING', loading: true });

      const result = await axios.get(`${API_URL}/${PATH}`);
      setCorruptionCase(result.data);
      dispatch({ type: 'SET_LOADING', loading: false });
    } catch (error) {
      dispatch({ type: 'SET_LOADING', loading: false });

      console.error(error);
    }
  };

  const getQuantByProvince = async () => {
    try {
      dispatch({ type: 'SET_LOADING', loading: true });

      const result = await axios.get(
        `${API_URL}/corruption-case/count-by-province`
      );
      setQuantByProvince(result.data);
      dispatch({ type: 'SET_LOADING', loading: false });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'SET_LOADING', loading: false });
    }
  };

  const getPhase = async () => {
    try {
      dispatch({ type: 'SET_LOADING', loading: true });
      const result = await axios.get<IPhase[]>(`${API_URL}/phase`);
      setPhases(result.data);
      dispatch({ type: 'SET_LOADING', loading: false });
    } catch (error) {
      console.error(error);
      dispatch({ type: 'SET_LOADING', loading: false });
    }
  };

  const getQuant = (provinceParam: string): number => {
    let quant = 0;

    quantByProvince.forEach((province) => {
      if (province.province == provinceParam) {
        quant = province._count;
      }
    });

    return quant;
  };

  const findProvinceByKey = (key: string): string => {
    let selected = '';
    provinces.forEach((province) => {
      if (province.value === key) {
        selected = province.name;
      }
    });

    return selected;
  };
  useEffect(() => {
    // execute on location change
    getItem();
  }, [location]);

  useEffect(() => {
    async function init() {
      await getQuantByProvince();
      await getPhase();
      // await getItem();
    }
    init();
    // setCorruptionCase(corruptionCaseDetails);
  }, []);

  return (
    <>
      <div className="row">
        <div className="col flex-2">
          <div className="_card">
            <div className="_card-header">
              <span color="theme-color">
                {findProvinceByKey(corruptionCase.province)}
              </span>
            </div>
            <div className="_card-body">
              <div className="process-detail">
                {' '}
                <b className="theme-color">Número do processo: </b>
                {corruptionCase.process_number}
              </div>
              <div className="process-detail">
                <b className="theme-color">Crime: </b>
                {corruptionCase.crime}
              </div>

              <div className="process-detail">
                <b className="theme-color">Fase do processo: </b>
                {corruptionCase.phase?.name}
              </div>
              <div className="process-detail">
                <b className="theme-color">Valor envolvido: </b>
                {corruptionCase.amount > 0
                  ? `${new Intl.NumberFormat('de-DE').format(
                      corruptionCase.amount
                    )} MT (${extenso(
                      corruptionCase.amount.toString().replace('.', ','),
                      { locale: 'br', number: { decimal: 'informal' } }
                    )} Meticais) `
                  : ''}
              </div>
              <div className="process-detail">
                <b className="theme-color">Local onde corre o processo: </b>
                {corruptionCase.address}
              </div>
              <div className="process-detail">
                <b className="theme-color">
                  Qualidade das pessoas envolvidas no processo:{' '}
                </b>
                {corruptionCase.defendant_type}
              </div>

              {corruptionCase.male_quant && (
                <div className="process-detail">
                  <b className="theme-color">Quantidade de homens: </b>
                  {corruptionCase.male_quant}
                </div>
              )}

              {corruptionCase.female_quant && (
                <div className="process-detail">
                  <b className="theme-color">Quantidade de mulheres: </b>
                  {corruptionCase.female_quant}
                </div>
              )}

              <div className="process-detail">
                {' '}
                <b className="theme-color">Data início do processo: </b>
                {corruptionCase?.start_date
                  ? corruptionCase.start_date.substring(0, 4)
                  : ''}
              </div>
              {corruptionCase?.end_date && (
                <div className="process-detail">
                  {' '}
                  <b className="theme-color">Data fim do processo: </b>
                  {corruptionCase?.end_date
                    ? corruptionCase.end_date.substring(0, 4)
                    : ''}
                </div>
              )}
              <br />
              {/* 
              <div>Pessoas no processo:</div>
              <div className="defendant-list">
                {corruptionCase.defendants.map((defendant) => (
                  <Link to={'/public/defendant/' + defendant.id}>
                    <span>
                      {defendant.first_name + ' ' + defendant.last_name}{' '}
                    </span>
                    ,
                  </Link>
                ))}
              </div> */}

              {corruptionCase.description && (
                <div className="case-details">
                  <b>
                    Descrição: <br />
                  </b>
                  {corruptionCase.description}
                </div>
              )}
              <div
                className="back-to-list"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {' '}
                Voltar a listagem de Processos
              </div>
            </div>
          </div>
          <div className="_card">
            <div className="_card-header">Observações:</div>
            <div className="_card-body">{corruptionCase.comments}</div>
          </div>
        </div>

        <div className="col flex-1 provinces-list">
          <div className="_card">
            <div className="_card-header">
              PROVÍNCIAS
              <br />
              <span style={{ fontSize: 12, fontWeight: 400 }}>
                {' '}
                * Entre parenteses quantidades por província
              </span>
            </div>
            <div className="_card-body ">
              <div className="faze-list">
                {provinces.map((province, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      navigate(
                        `/public/?province=${province.value}&categoryId=0&year=0`
                      );
                    }}
                    className={
                      province.value == corruptionCase.province
                        ? 'theme-color'
                        : ''
                    }
                  >
                    {province.value == corruptionCase.province ? (
                      <i className="fa fa-arrow-right"></i>
                    ) : (
                      ''
                    )}
                    {province.name} - ({getQuant(province.value)})
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CorruptionCaseDetails;
