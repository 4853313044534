import React, { useEffect, useState } from 'react';
import '../assets/css/home.css';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CurrencyFormat from '../components/CurrencyFormat';
import PageTitle from '../components/PageTitle';
import Row from '../components/Row';
import StatsCard from '../components/StatsCard';
import { Reducers } from '../store/reducers';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';
import Card from '../components/card';
import VerticalBarChart from '../components/charts/VerticalBarChart';
import axios from 'axios';
import { IProvincesCount, provinces } from '../types';
import { ICorruptionCaseDTO } from '../types/ICorruptionCase';
import { ICategory } from '../types/ICategory';
import { getProvinceName } from '../utils';

const YEARS = [2024, 2023, 2022, 2021, 2020, 2019];
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Gráficos de ocorrências',
    },
  },
};

type LabelNumber = {
  label: string;
  value: number;
};

type LabelNumberId = {
  label: string;
  value: number;
  id: number;
  type: string;
};

const PROCESS_TYPES = {
  PUBLIC: 'publico',
  PRIVATE: 'privado',
  OTHERS: 'outros',
};

const labels = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export default function Home() {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'corruption-case/count-by-province';

  const [currentProcessType, setCurrentProcessType] = useState('publico');
  const [currentYear, setCurrentYear] = useState(0);
  const [total, setTotal] = useState(0);
  const [quantMen, setQuantMen] = useState(0);
  const [quantWomen, setQuantWomen] = useState(0);
  const [graphOrientation, setGraphOrientation] = useState('y');

  const [provincesQuant, setProvinceQuant] = useState<number[]>(
    provinces.map((province) => {
      return 5;
    })
  );

  const [corruptionCases, setCorruptionCases] = useState<ICorruptionCaseDTO[]>(
    []
  );

  const [categories, setCategories] = useState<ICategory[]>([]);

  const [statsByProvinceData, setStatsByProvinceData] = useState({
    labels: provinces.map((province) => province.value),
    datasets: [
      {
        label: 'Quantidade por província',
        data: provincesQuant,
        backgroundColor: 'rgba(13, 222, 35)',
      },
    ],
  });

  const [statsByCategoryData, setStatsByCategoryData] = useState({
    labels: provinces.map((province) => province.value),
    datasets: [
      {
        label: 'Quantidade por categoria',
        data: provincesQuant,
        backgroundColor: 'rgba(13, 222, 35)',
      },
    ],
  });

  const navigate = useNavigate();

  const fetchProcessList = async () => {
    try {
      const result = await axios.get<ICorruptionCaseDTO[]>(
        `${API_URL}/corruption-case/for-filter`
      );
      setCorruptionCases(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const setProvincesData = async () => {
    const mainArray: LabelNumber[] = provinces.map((province) => {
      return { label: province.value, value: 0 };
    });

    const arrayData = corruptionCases;
    for (let i = 0; i < arrayData.length; i++) {
      for (let j = 0; j < mainArray.length; j++) {
        const tempYear = arrayData[i].start_date
          ? new Date(arrayData[i].start_date as string).getFullYear()
          : 2000;
        if (
          mainArray[j].label === arrayData[i].province &&
          arrayData[i].category?.type === currentProcessType &&
          currentYear === tempYear
        ) {
          mainArray[j].value += 1;
        } else if (
          mainArray[j].label === arrayData[i].province &&
          arrayData[i].category?.type === currentProcessType &&
          currentYear === 0
        ) {
          mainArray[j].value += 1;
        }
      }
    }

    setStatsByProvinceData({
      labels: mainArray.map((item) => {
        return getProvinceName(item.label);
      }),
      datasets: [
        {
          label: 'Quantidade de casos',
          data: mainArray.map((item) => item.value),
          backgroundColor: 'rgba(13, 222, 35)',
        },
      ],
    });
    console.log(mainArray);
  };

  const setCategoriesData = async () => {
    let tempTotal = 0;
    let tempMen = 0;
    let tempWomen = 0;
    try {
      const result = await axios.get<ICategory[]>(`${API_URL}/category`);

      const mainArray: LabelNumberId[] = result.data
        .filter((category) => {
          return category.type === currentProcessType;
        })
        .map((category) => {
          return {
            id: category.id,
            value: 0,
            label: category.name,
            type: category.type,
          };
        });
      const arrayData = corruptionCases;

      for (let i = 0; i < arrayData.length; i++) {
        // const element = result.data[i];
        for (let j = 0; j < mainArray.length; j++) {
          const tempYear = arrayData[i].start_date
            ? new Date(arrayData[i].start_date as string).getFullYear()
            : 2000;
          if (
            mainArray[j].id === arrayData[i].category_id &&
            arrayData[i].category?.type === currentProcessType &&
            currentYear === tempYear
          ) {
            mainArray[j].value += 1;

            // Totals
            tempTotal++;
            tempMen += arrayData[i].male_quant;
            tempWomen += arrayData[i].female_quant;
          } else if (
            mainArray[j].id === arrayData[i].category_id &&
            arrayData[i].category?.type === currentProcessType &&
            currentYear === 0
          ) {
            mainArray[j].value += 1;

            // Totals
            tempTotal++;
            tempMen += arrayData[i].male_quant;
            tempWomen += arrayData[i].female_quant;
          }
        }

        setQuantMen(tempMen);
        setQuantWomen(tempWomen);
        setTotal(tempTotal);
      }

      setStatsByCategoryData({
        labels: mainArray.map((item) => item.label),
        datasets: [
          {
            label: 'Quantidade de casos',
            data: mainArray.map((item) => item.value),
            backgroundColor: 'rgba(13, 222, 35)',
          },
        ],
      });
      console.log(mainArray);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(new Date().getFullYear());

    fetchProcessList();
    // getQuantByProvince();
  }, []);

  useEffect(() => {
    if (corruptionCases.length > 0) {
      setProvincesData();
      setCategoriesData();
    }
  }, [currentYear, corruptionCases, currentProcessType]);

  const ongoingCases = () => {
    return corruptionCases.filter((corruptionCase) => {
      // return corruptionCase?.phase?.id > 0 && corruptionCase?.phase?.id < 5;
      return 0;
    });
  };

  const finishedCases = () => {
    return corruptionCases.filter((corruptionCase) => {
      return corruptionCase.phase?.id === 5;
    });
  };

  return (
    <>
      <PageTitle>Gráficos de casos de corrupção</PageTitle>
      <Row>
        <select
          name=""
          onChange={(e) => {
            setCurrentProcessType(e.target.value);
          }}
        >
          <option value="publico">Público</option>

          <option value="privado">Privado</option>
          <option value="outros">Outros</option>
        </select>

        <select
          name=""
          value={currentYear.toString()}
          onChange={(e) => {
            setCurrentYear(parseInt(e.target.value));
          }}
        >
          <option value="0">Todos anos</option>
          {YEARS.map((year) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </Row>
      <Row>
        <div className="stats-card-list">
          <StatsCard
            desc="Filtragem atual"
            number={currentProcessType}
            icon="check"
            iconColor="#fff"
            backgroundIconColor="#E3642F"
          ></StatsCard>
          <StatsCard
            desc="Total de casos na filtragem"
            number={total + ''}
            icon="file-invoice"
            iconColor="#fff"
            backgroundIconColor="#2d3792"
          ></StatsCard>
          {/* 
                <StatsCard desc="Total em dívida" number={<CurrencyFormat value={totalDebit}></CurrencyFormat>} icon='file-invoice-dollar' iconColor="#fff" backgroundIconColor='#00f9' >
                </StatsCard> */}
          <StatsCard
            desc="Total homens envolvidos"
            number={quantMen.toString()}
            icon="male"
            iconColor="#fff"
            backgroundIconColor="#00a8ff"
          ></StatsCard>
          <StatsCard
            desc="Total mulheres envolvidas"
            number={quantWomen.toString()}
            icon="female"
            iconColor="#fff"
            backgroundIconColor="#dc138b"
          ></StatsCard>
        </div>
      </Row>

      <div className="card ">
        <div className="card-header">
          <h3>Quantidade por província</h3>
        </div>
        <div className="card-body">
          {/* <Bar className='anual-chart' options={options} data={data} /> */}
          <VerticalBarChart
            styleClasses="anual-chart"
            options={{
              ...options,
              // responsive: true,
              // maintainAspectRatio: false,
              indexAxis: 'y',
            }}
            data={statsByProvinceData}
          ></VerticalBarChart>
        </div>
      </div>

      <div className="card ">
        <div className="card-header">
          <h3>Quantidade por categoria</h3>
        </div>
        <div className="card-body">
          {/* <Bar className='anual-chart' options={options} data={data} /> */}
          <VerticalBarChart
            styleClasses="anual-chart"
            options={{
              ...options,
              // responsive: true,
              // maintainAspectRatio: false,
              indexAxis: 'y',
            }}
            data={statsByCategoryData}
          ></VerticalBarChart>
        </div>
      </div>
    </>
  );
}
