import { IDefendant } from '../../types/IDefendant';

export interface UtilState {
  apiUrl: string;
  token: string;
  defendants: IDefendant[];
  loading: boolean;
}

const INITIAL_STATE = {
  // apiUrl: 'http://localhost:8080/api',
  apiUrl:
    process.env.NODE_ENV == 'production'
      ? 'https://basededadosdecorrupcao.org/api'
      : 'http://localhost:3001/api',
  token: '',
  loading: false,
  defendants: [],
};

interface Action {
  type: string;
  apiUrl: string;
  token: string;
  loading: boolean;
  defendants: IDefendant[];
}

function util(state: UtilState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case 'SET_URL':
      return { ...state, apiUrl: action.apiUrl };
    case 'SET_TOKEN':
      return { ...state, token: action.token };
    case 'SET_DEFENDANTS':
      return { ...state, defendants: action.defendants };
    case 'SET_LOADING':
      return { ...state, loading: action.loading };
    default:
      return state;
  }
}

export default util;
