export interface Invoice {
  counter: string;
  number: string;
  date: string;
  amount: number;
  paidOut: boolean;
}

interface Action {
  type: string;
  invoice: Invoice;
}

export interface InvoiceState {
  data: Invoice[];
}

const INITIAL_STATE: InvoiceState = {
  data: [
    {
      counter: "332543654654",
      number: "543653654654",
      date: "11-11-2021",
      amount: 4021.5,
      paidOut: false,
    },
    {
      counter: "1132543653654654",
      number: "332543654654",
      date: "11-12-2021",
      amount: 221.04,
      paidOut: false,
    },
    {
      counter: "22254453653654654",
      number: "22253654654",
      date: "01-01-2022",
      amount: 122021,
      paidOut: true,
    },
    {
      counter: "44354532543653654654",
      number: "32543653654654",
      date: "10-02-2022",
      amount: 62021.23,
      paidOut: true,
    },
  ],
};

function invoices(
  state: InvoiceState = INITIAL_STATE,
  action: Action
): InvoiceState {
  switch (action.type) {
    case "ADD_INVOICE":
      return { ...state, data: [...state.data, action.invoice] };
    case "PAY_INVOICE":
      return {
        ...state,
        data: state.data.map((invoice) => {
          if (invoice.number === action.invoice.number) {
            invoice.paidOut = true;
          }
          return invoice;
        }),
      };
    default:
      return state;
  }
}

export default invoices;
