import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/card';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Reducers } from '../../store/reducers';
import { Counter } from '../../store/reducers/countersReducer';
import { IMedicine } from '../../types';
import axios from 'axios';
import { log } from 'console';
// import { Container } from './styles';

const UpdateMedicine: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);

  const [medicine, setMedicine] = useState<IMedicine>({
    _id: '',
    code: '',
    name: '',
    type: '',
    category: '',
    description: '',
  });
  const navigate = useNavigate();
  const { id } = useParams();

  const getMedicine = async () => {
    try {
      const result = await axios.get(`${API_URL}/medicine/${id}`);
      setMedicine(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const update = async () => {
    // console.log(API_URL);
    let data = medicine;
    delete data._id;
    try {
      const result = await axios.put(`${API_URL}/medicine/${id}`, medicine);
      console.log(result.data);
      navigate('/medicines');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getMedicine();
  }, []);

  return (
    <>
      <PageTitle>
        Editar medicamento:{' '}
        <span className="font-color-primary">{medicine.name}</span>
      </PageTitle>
      <Card>
        <FormGroup>
          <label htmlFor="">Código</label>
          <input
            type="text"
            placeholder="Código"
            value={medicine.code}
            onChange={(e) => {
              setMedicine({ ...medicine, code: e.target.value });
            }}
          />
          {/* <span className='error'>* Error rerere rere</span> */}
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Nome</label>
          <input
            type="text"
            placeholder="Nome"
            value={medicine.name}
            onChange={(e) => {
              setMedicine({ ...medicine, name: e.target.value });
            }}
          />
          {/* <span className='error'>* Error rerere rere</span> */}
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo</label>
          <input
            type="text"
            placeholder="Tipo"
            value={medicine.type}
            onChange={(e) => {
              setMedicine({ ...medicine, type: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Categoria</label>
          <input
            type="text"
            placeholder="Categoria"
            value={medicine.category}
            onChange={(e) => {
              setMedicine({ ...medicine, category: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Descrição</label>
          <input
            type="text"
            placeholder="Descrição"
            value={medicine.description}
            onChange={(e) => {
              setMedicine({ ...medicine, description: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <div className="row">
            <button className="button button-success" onClick={update}>
              Salvar
            </button>
            <button
              className="button button-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </button>
          </div>
        </FormGroup>
      </Card>
    </>
  );
};

export default UpdateMedicine;
