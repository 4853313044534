import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/card';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Reducers } from '../../store/reducers';
import { Counter } from '../../store/reducers/countersReducer';
import { ISanitaryUnity } from '../../types';
import axios from 'axios';
import { log } from 'console';
// import { Container } from './styles';

const UpdateSanitaryUnity: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);

  const [sanitaryUnity, setSanitaryUnity] = useState<ISanitaryUnity>({
    _id: '',
    code: '',
    name: '',
    type: '',
    category: '',
    region: '',
    province: '',
    address: '',
    description: '',
  });
  const navigate = useNavigate();
  const { id } = useParams();

  const getSanitaryUnity = async () => {
    try {
      const result = await axios.get(`${API_URL}/sanitary-unity/${id}`);
      setSanitaryUnity(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const update = async () => {
    // console.log(API_URL);
    let data = sanitaryUnity;
    delete data._id;
    try {
      const result = await axios.put(
        `${API_URL}/sanitary-unity/${id}`,
        sanitaryUnity
      );
      console.log(result.data);
      navigate('/sanitary-unities');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSanitaryUnity();
  }, []);

  return (
    <>
      <PageTitle>
        Editar unidade sanitária:{' '}
        <span className="font-color-primary">{sanitaryUnity.name}</span>
      </PageTitle>
      <Card>
        <FormGroup>
          <label htmlFor="">Código</label>
          <input
            type="text"
            placeholder="Código"
            value={sanitaryUnity.code}
            onChange={(e) => {
              setSanitaryUnity({ ...sanitaryUnity, code: e.target.value });
            }}
          />
          {/* <span className='error'>* Error rerere rere</span> */}
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Nome</label>
          <input
            type="text"
            placeholder="Nome"
            value={sanitaryUnity.name}
            onChange={(e) => {
              setSanitaryUnity({ ...sanitaryUnity, name: e.target.value });
            }}
          />
          {/* <span className='error'>* Error rerere rere</span> */}
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Região</label>
          <input
            type="text"
            placeholder="Região"
            value={sanitaryUnity.region}
            onChange={(e) => {
              setSanitaryUnity({ ...sanitaryUnity, region: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Província</label>
          <input
            type="text"
            placeholder="Província"
            value={sanitaryUnity.province}
            onChange={(e) => {
              setSanitaryUnity({ ...sanitaryUnity, province: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Endereço</label>
          <input
            type="text"
            placeholder="Endereço"
            value={sanitaryUnity.address}
            onChange={(e) => {
              setSanitaryUnity({ ...sanitaryUnity, address: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Tipo</label>
          <input
            type="text"
            placeholder="Tipo"
            value={sanitaryUnity.type}
            onChange={(e) => {
              setSanitaryUnity({ ...sanitaryUnity, type: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Categoria</label>
          <input
            type="text"
            placeholder="Categoria"
            value={sanitaryUnity.category}
            onChange={(e) => {
              setSanitaryUnity({ ...sanitaryUnity, category: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Descrição</label>
          <input
            type="text"
            placeholder="Descrição"
            value={sanitaryUnity.description}
            onChange={(e) => {
              setSanitaryUnity({
                ...sanitaryUnity,
                description: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <div className="row">
            <button className="button button-success" onClick={update}>
              Salvar
            </button>
            <button
              className="button button-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </button>
          </div>
        </FormGroup>
      </Card>
    </>
  );
};

export default UpdateSanitaryUnity;
