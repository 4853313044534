import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import Row from '../../components/Row';
import StatsCard from '../../components/StatsCard';
import { Reducers } from '../../store/reducers';
import { Counter } from '../../store/reducers/countersReducer';
import axios from 'axios';
import { IDefendant } from '../../types/IDefendant';

const Defendants: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'defendant';
  const counters = useSelector((state: Reducers) => state.counters.data);
  const dispatch = useDispatch();
  const [defendants, setDefendants] = useState<IDefendant[]>([]);

  const getLsit = async () => {
    try {
      const result = await axios.get(`${API_URL}/${PATH}`);
      setDefendants(result.data);
      console.log(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const remove = async (defendant: IDefendant) => {
    try {
      const result = await axios.delete(`${API_URL}/defendant/${defendant.id}`);
      console.log(result.data);
      getLsit();
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getLsit();
  }, []);

  return (
    <>
      <PageTitle>Arguídos</PageTitle>
      {/* <Row>

                <StatsCard desc="Medicamentos Registrados" number={defendants.length + ""} icon='stopwatch-20' iconColor="#fff" backgroundIconColor='#E3642F'>
                </StatsCard>

            </Row> */}
      <div className="card table-card">
        <div className="card-header">
          <h3>Lista de Arguídos</h3>
        </div>
        <div className="card-body">
          <div className="card no-style">
            <Link to="/defendants/new">
              <button className="button button-primary">Adicionar novo</button>
            </Link>
          </div>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Instituição</th>
                  <th>Posição</th>
                  <th>Nacionalidade</th>

                  <th>Província/Cidade</th>
                  <th>Acção</th>
                </tr>
              </thead>
              <tbody>
                {defendants.map((defendant) => (
                  <tr key={defendant.id}>
                    <td>
                      {' '}
                      {defendant.first_name} {defendant.last_name}{' '}
                    </td>
                    <td>{defendant.company}</td>

                    <td>{defendant.position}</td>

                    <td>{defendant.nationality}</td>
                    <td>{defendant.province}</td>

                    <td style={{ gap: '5px' }}>
                      <Link to={`/defendants/${defendant.id}`}>
                        <button className="button button-primary">
                          Editar
                        </button>
                      </Link>
                      <button
                        className="button button-danger"
                        onClick={() => {
                          remove(defendant);
                        }}
                      >
                        Apagar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Defendants;
