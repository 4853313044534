import React from 'react';

// import { Container } from './styles';

interface Props {
  desc: string;
  number: any;
  icon: string;
  iconColor: string;
  backgroundIconColor: string;
}
const StatsCard: React.FC<Props> = ({
  children,
  desc,
  number,
  icon,
  iconColor,
  backgroundIconColor,
}) => {
  return (
    <div className="stats-card">
      <div className="icon" style={{ backgroundColor: backgroundIconColor }}>
        <i className={'fa fa-' + icon} style={{ color: iconColor }}></i>
      </div>
      <div className="stats-body">
        <div className="number">{number}</div>
        <div className="desc">{desc}</div>
      </div>
    </div>
  );
};

export default StatsCard;
