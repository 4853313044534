import React from 'react';



const PageTitle: React.FC = ({ children }) => {
    return (
        <div className='page-title'>
            {children}
        </div>)
        ;
}

export default PageTitle;