import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Reducers } from '../../store/reducers';
import { Counter } from '../../store/reducers/countersReducer';
import { IDefendant, IDefendantDTO } from '../../types/IDefendant';
import axios from 'axios';
import { gender, provinces } from '../../types';
// import { Container } from './styles';

const NewDefendant: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'defendant';
  const [defendant, setDefendant] = useState<IDefendantDTO>({
    id: '',
    first_name: '',
    last_name: '',
    nationality: 'Moçambicano',
    province: 'MAPUTO',
    defendant_type: 'Pessoa Pública',
    position: '',
    company: '',
    birth_date: '',
    gender: 'M',
  });
  const navigate = useNavigate();

  const create = async () => {
    if (
      defendant.first_name.trim().length === 0 ||
      defendant.last_name.trim().length === 0
    ) {
      return;
    }
    console.log(defendant);
    let data = { ...defendant };
    delete data.id;
    try {
      const result = await axios.post(`${API_URL}/${PATH}`, data);
      console.log(result.data);
      navigate('/defendants');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <PageTitle>Adicionar arguído</PageTitle>
      <Card>
        <FormGroup>
          <label htmlFor="">Nome</label>
          <input
            type="text"
            placeholder="Nome"
            value={defendant.first_name}
            onChange={(e) => {
              setDefendant({ ...defendant, first_name: e.target.value });
            }}
          />
          {/* <span className='error'>* Error rerere rere</span> */}
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Apelido</label>
          <input
            type="text"
            placeholder="Apelido"
            value={defendant.last_name + ''}
            onChange={(e) => {
              setDefendant({ ...defendant, last_name: e.target.value });
            }}
          />
          {/* <span className='error'>* Error rerere rere</span> */}
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Província</label>
          <select
            onChange={(e) => {
              setDefendant({ ...defendant, province: e.target.value });
            }}
          >
            {provinces.map((province) => (
              <option value={province.value}>{province.name}</option>
            ))}
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Nacionalidade</label>
          <input
            type="text"
            placeholder="Nacionalidade"
            value={defendant.nationality}
            onChange={(e) => {
              setDefendant({ ...defendant, nationality: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Qualidade do Reu</label>
          <select
            onChange={(e) => {
              setDefendant({ ...defendant, defendant_type: e.target.value });
            }}
          >
            <option value="Pessoa Pública">Pessoa Pública</option>
            <option value="Pessoa Privada">Pessoa Privada</option>
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Gênero</label>
          <select
            onChange={(e) => {
              setDefendant({ ...defendant, gender: e.target.value });
            }}
          >
            {gender.map((gender) => (
              <option value={gender.value}>{gender.name}</option>
            ))}
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Empresa</label>
          <input
            type="text"
            placeholder="Empresa"
            value={defendant.company}
            onChange={(e) => {
              setDefendant({ ...defendant, company: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Posição na empresa</label>
          <input
            type="text"
            placeholder="Cargo"
            value={defendant.position}
            onChange={(e) => {
              setDefendant({ ...defendant, position: e.target.value });
            }}
          />
        </FormGroup>

        <FormGroup>
          <div className="row">
            <button className="button button-success" onClick={create}>
              Salvar
            </button>
            <button
              className="button button-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </button>
          </div>
        </FormGroup>
      </Card>
    </>
  );
};

export default NewDefendant;
