export interface User {
  username: string;
  password: string;
  role: string;
}

interface Action {
  type: string;
  user: User;
}

export interface UserState {
  data: User[];
}

const INITIAL_STATE: UserState = {
  data: [
    {
      username: "user1",
      password: "password1",
      role: "admin",
    },
  ],
};

function users(state: UserState = INITIAL_STATE, action: Action): UserState {
  switch (action.type) {
    case "ADD_USER":
      return { ...state, data: [...state.data, action.user] };
    default:
      return state;
  }
}

export default users;
