import React, { useEffect, useRef, useState } from 'react';
import '../assets/css/all.min.css';
import '../assets/css/style.css';
import '../assets/css/components.css';
import logo from '../assets/img/logo.png';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { Reducers } from '../store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export default function Main() {
  const firstRender = useRef<boolean>(true);
  const account = useSelector((state: Reducers) => state.account);
  // const TOKEN = useSelector((state: Reducers) => state.util.token);

  const [menuItems, setMenuItems] = useState<string[]>(['#f4f5fa']);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectItem = (menu: number) => {
    let temp = menuItems;
    for (let i = 0; i < temp.length; i++) {
      temp[i] = '#fff';
    }

    temp[menu] = '#f4f5fa';
    setMenuItems(temp);
  };
  const verifyToken = () => {
    const path = window.location.pathname;
    if (path.includes('public') || path === '/') {
      return;
    }

    const TOKEN = localStorage.getItem('TOKEN');
    // console.log('TOKEN: ' + TOKEN);

    if (!TOKEN) {
      navigate('/login');
    } else {
      dispatch({
        type: 'SET_TOKEN',
        token: TOKEN,
      });
      try {
        const decoded = jwtDecode(TOKEN);
        if (decoded.exp) {
          if (decoded.exp * 1000 < Date.now()) {
            localStorage.removeItem('TOKEN');
            navigate('/login');
          } else {
            axios.defaults.headers.common['Authorization'] = `Bearer ${TOKEN}`;
          }
        }
      } catch (error) {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    if (firstRender) {
      firstRender.current = false;
      window.onfocus = () => {
        verifyToken();
      };
    }
    verifyToken();
  }, []);

  return (
    <div>
      <input type="checkbox" id="menu-toggle" hidden />
      <div className="side-bar">
        <header>
          <img className="logo" src={logo} alt="" />
          <img className="small-logo" src={logo} alt="" />
        </header>
        <div className="side-bar-menu">
          <ul>
            <li>
              <Link
                onClick={() => selectItem(0)}
                className="menu-item"
                style={{ backgroundColor: menuItems[0] }}
                to="/home"
              >
                <i className="fa fa-home"></i>
                <span> Dashboard </span>
              </Link>
            </li>
            <li>
              <Link
                className="menu-item"
                onClick={() => selectItem(1)}
                style={{ backgroundColor: menuItems[1] }}
                to="/corruption-cases"
              >
                <i className="fa fa-file-invoice"></i>
                <span> Processos </span>
              </Link>
            </li>
            {process.env.NODE_ENV === 'development' && (
              <>
                <li>
                  <Link
                    className="menu-item"
                    onClick={() => selectItem(4)}
                    style={{ backgroundColor: menuItems[4] }}
                    to="/defendants"
                  >
                    <i className="fa fa-stopwatch-20"></i>
                    <span>Arguídos</span>
                  </Link>
                </li>

                <li>
                  <Link
                    className="menu-item"
                    onClick={() => selectItem(5)}
                    style={{ backgroundColor: menuItems[5] }}
                    to="/courts"
                  >
                    <i className="fa fa-gavel"></i>
                    <span>Tribunais</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      <div className="nav-bar">
        <label className="menu-toggle fa fa-bars" htmlFor="menu-toggle"></label>
        <div className="notification-wrapper">
          Bem vindo,{' '}
          <span style={{ fontWeight: 'bold' }}>
            {account.currentUser.username}
          </span>
        </div>

        <div className="notification-wrapper">
          <i className="fa fa-bell"></i>
        </div>

        {/* <div className="search-wrapper">
                    <input type="text" placeholder="Search" />
                    <i className="fa fa-search"></i>
                </div> */}

        <div className="profile-wrapper">
          <i className="fa fa-user"></i>
          <ul className="profile-options">
            <li>
              <i className="fa fa-user"></i>
              {account.currentUser.username}
            </li>
            <li>
              <i className="fa fa-user-cog"></i>
              Perfil
            </li>
            <li>
              <i className="fa fa-info-circle"></i>
              Info
            </li>
            <li
              onClick={() => {
                axios.defaults.headers.common['Authorization'] = '';
                dispatch({
                  type: 'SET_TOKEN',
                  token: '',
                });
                localStorage.removeItem('TOKEN');
                document.location.reload();
              }}
            >
              <i className="fa fa-sign-out-alt"></i>
              sair
            </li>
          </ul>
        </div>
      </div>
      <div className="main">
        <Outlet />
      </div>
    </div>
  );
}
