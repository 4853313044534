import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Card from '../../components/card';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Reducers } from '../../store/reducers';
import { provinces } from '../../types';
import { Counter } from '../../store/reducers/countersReducer';
import {
  ICorruptionCase,
  ICorruptionCaseDTO,
} from '../../types/ICorruptionCase';
import axios from 'axios';
import Select, { MultiValue } from 'react-select';

import { IDefendant } from '../../types/IDefendant';
import { ICategory } from '../../types/ICategory';
import { ICourt } from '../../types/ICourt';
import { IPhase } from '../../types/IPhase';
// import { Container } from './styles';

const NewCorruptionCase: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const dispatch = useDispatch();
  const PATH = 'corruption-case';
  const { id } = useParams();
  const storedDefendants = useSelector(
    (state: Reducers) => state.util.defendants
  );
  const [defendantList, setDefendantList] = useState<
    { label: string; value: string }[]
  >(
    storedDefendants.map((defendant) => {
      return {
        label: defendant.first_name + ' ' + defendant.last_name,
        value: defendant.id,
      };
    })
  );

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [courts, setCourts] = useState<ICourt[]>([]);
  const [phases, setPhases] = useState<IPhase[]>([]);

  const [corruptionCase, setCorruptionCase] = useState<ICorruptionCaseDTO>({
    title: '',
    crime: '',
    address: '',
    process_number: '',
    city: '',
    province: '',
    description: '',
    comments: '',
    defendantIDs: [],
    defendants: [],
    phase_id: 1,
    defendant_type: '',
    amount: 0,
    category_id: 0,
    court_id: null,
    priority: 0,
    court_type: 1,
    male_quant: 0,
    female_quant: 0,
  });

  const navigate = useNavigate();

  const getItem = async () => {
    try {
      dispatch({ type: 'SET_LOADING', loading: true });

      const result = await axios.get(`${API_URL}/${PATH}/${id}`);
      setCorruptionCase(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCategories = async () => {
    try {
      const result = await axios.get<ICategory[]>(`${API_URL}/category`);
      setCategories(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCourts = async () => {
    try {
      const result = await axios.get<ICourt[]>(`${API_URL}/court`);
      setCourts(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCourtsByProvince = async (province: string) => {
    try {
      const result = await axios.get<ICourt[]>(
        `${API_URL}/court/province/${province}`
      );
      setCourts(result.data);
      if (result.data.length > 0) {
        setCorruptionCase({
          ...corruptionCase,
          court_id: result.data[0].id,
          province,
        });
      } else {
        setCorruptionCase({ ...corruptionCase, court_id: 0, province });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getPhases = async () => {
    try {
      const result = await axios.get<IPhase[]>(`${API_URL}/phase`);
      setPhases(result.data);
    } catch (error) {
      console.error(error);
    }
  };

  const validateFrom = () => {
    let valid = true;
    if (corruptionCase.crime.trim() === '') {
      console.warn('Escreva o crime');

      valid = false;
    }

    if (corruptionCase.category_id === 0) {
      console.warn('Escolha a categoria');
      valid = false;
    }

    if (corruptionCase.province === '') {
      console.warn('Escolha a província');
      valid = false;
    }
    return valid;
  };

  const create = async () => {
    if (!validateFrom()) {
      console.warn('Invalid form');

      return;
    }
    let data = { ...corruptionCase };
    delete data.id;
    try {
      if (id) {
        const result = await axios.put(`${API_URL}/${PATH}/${id}`, data);
      } else {
        const result = await axios.post(`${API_URL}/${PATH}`, data);
      }
      // return;
      navigate('/corruption-cases');
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategories();
    getPhases();
    if (id) {
      getItem();
    }
    // getCourts();
  }, []);

  return (
    <>
      <PageTitle>Processos</PageTitle>
      <Card>
        <FormGroup>
          <label htmlFor="">Número do processo</label>
          <input
            type="text"
            placeholder="Número do processo"
            value={corruptionCase.process_number}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                process_number: e.target.value.trim(),
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Crime</label>
          <input
            type="text"
            placeholder="Crime"
            value={corruptionCase.crime}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                title: e.target.value,
                crime: e.target.value,
              });
            }}
          />
        </FormGroup>

        {/* <FormGroup>
          <label htmlFor="">Título</label>
          <input
            type="text"
            placeholder="Titulo"
            value={corruptionCase.title}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                title: e.target.value,
              });
            }}
          />
        </FormGroup> */}
        <FormGroup>
          <label htmlFor="">
            Tribunal ou Ministério Público (atual =
            {corruptionCase.court_type.toString()})
          </label>
          <select
            name=""
            id=""
            value={corruptionCase.court_type.toString()}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                court_type: parseInt(e.target.value),
              });
            }}
          >
            <option value="1">Tribunal</option>
            <option value="2">Ministério Público</option>
            <option value="3">Gabinete de combate a corrupção</option>
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Local onde ocorre o processo</label>
          <input
            type="text"
            placeholder="Local onde ocorre"
            value={corruptionCase.address}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                address: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Província</label>
          <select
            name=""
            id=""
            defaultValue={''}
            value={corruptionCase.province}
            onChange={(e) => {
              getCourtsByProvince(e.target.value);
              setCorruptionCase({
                ...corruptionCase,
                province: e.target.value,
              });
            }}
          >
            <option value="" disabled defaultValue={''}>
              ---- Selecione a Província ----
            </option>
            {provinces.map((province) => (
              <option key={province.value} value={province.value}>
                {' '}
                {province.name}
              </option>
            ))}
          </select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="">
            Data início (atual = {corruptionCase.start_date})
          </label>
          <input
            type="date"
            // value={
            //   corruptionCase.start_date
            //     ? corruptionCase.start_date
            //     : new Date().toISOString()
            // }
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                start_date: new Date(e.target.value).toISOString(),
              });
            }}
          ></input>
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Relevância</label>
          <select
            name=""
            id=""
            defaultValue={'0'}
            value={corruptionCase.priority}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                priority: parseInt(e.target.value),
              });
            }}
          >
            <option value="1">Em destaque</option>
            <option value="0">Normal</option>
          </select>
        </FormGroup>
        {/* <FormGroup>
          <label htmlFor="">Tribunal</label>
          <select
            name=""
            id=""
            // defaultValue={''}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                court_id: parseInt(e.target.value),
              });
            }}
          >
            <option value="0" disabled>
              ---- Selecione o Tribunal ----
            </option>
            {courts.map((court) => (
              <option key={court.id} value={court.id}>
                {' '}
                {court.name}
              </option>
            ))}
          </select>
        </FormGroup> */}

        <FormGroup>
          <label htmlFor="">Categoria</label>
          <select
            name=""
            id=""
            defaultValue={'0'}
            value={corruptionCase.category_id}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                category_id: parseInt(e.target.value),
              });
            }}
          >
            <option value="0" disabled>
              ---- Selecione a categoria ----
            </option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.type}
                {' - '}
                {category.name}
              </option>
            ))}
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Fase</label>
          <select
            name=""
            id=""
            // defaultValue={''}
            value={corruptionCase.phase_id}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                phase_id: parseInt(e.target.value),
              });
            }}
          >
            <option value="0" disabled>
              ---- Selecione a faze ----
            </option>
            {phases.map((phase) => (
              <option key={phase.id} value={phase.id}>
                {' '}
                {phase.name}
              </option>
            ))}
          </select>
        </FormGroup>
        <FormGroup>
          <label htmlFor="">
            Qualidade das pessoas envolvidas no processo:
          </label>
          <input
            type="text"
            placeholder="Pessoas envolvidas"
            value={corruptionCase.defendant_type}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                defendant_type: e.target.value,
              });
            }}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Valor envolvido</label>
          <input
            type="number"
            placeholder="Valor"
            value={corruptionCase.amount}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                amount: e.target.value.trim() ? parseFloat(e.target.value) : 0,
              });
            }}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Homens (Quantidade dos envolvidos)</label>
          <input
            type="number"
            placeholder="quantidade de homens"
            value={corruptionCase.male_quant}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                male_quant: e.target.value.trim()
                  ? Math.abs(parseInt(e.target.value))
                  : 0,
              });
            }}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Mulheres (Quantidade dos envolvidos)</label>
          <input
            type="number"
            placeholder="quantidade de mulheres"
            value={corruptionCase.female_quant}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                female_quant: e.target.value.trim()
                  ? Math.abs(parseInt(e.target.value))
                  : 0,
              });
            }}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor=""> Descrição</label>
          <input
            type="text"
            placeholder="Descrição"
            value={corruptionCase.description + ''}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                description: e.target.value,
              });
            }}
          />
          {/* <span className='error'>* Error rerere rere</span> */}
        </FormGroup>

        {/* <FormGroup>
          <label htmlFor="">Arguidos</label>
          <Select
            isMulti
            options={defendantList}
            onChange={selectedDefendantHandler}
          />
        </FormGroup> */}
        {/* 
        <FormGroup>
          <label htmlFor="">Cidade/Distrito</label>
          <input
            type="text"
            placeholder="Cidade/Distrito"
            value={corruptionCase.city}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                city: e.target.value,
              });
            }}
          />
        </FormGroup> */}
        <FormGroup>
          <label htmlFor="">Observações</label>
          <input
            type="text"
            placeholder="Observações"
            value={corruptionCase.comments + ''}
            onChange={(e) => {
              setCorruptionCase({
                ...corruptionCase,
                comments: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <div className="row">
            <button className="button button-success" onClick={create}>
              Salvar
            </button>
            <button
              className="button button-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </button>
          </div>
        </FormGroup>
      </Card>
    </>
  );
};

export default NewCorruptionCase;
