import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../components/card';
import FormGroup from '../components/FormGroup';
import logo from '../assets/img/logo.png';
import { Reducers } from '../store/reducers';
import axios, { AxiosError } from 'axios';

export default function Login() {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const checkToken = () => {
    if (localStorage.getItem('TOKEN')) {
      navigate('/home');
    }
  };

  const login = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    try {
      const result = await axios.post(`${API_URL}/login`, {
        email: username,
        password,
      });
      navigate('/home');
      axios.defaults.headers.common['Authorization'] = `Bearer ${result.data}`;
      dispatch({
        type: 'SET_TOKEN',
        token: result.data,
      });
      localStorage.setItem('TOKEN', result.data);
    } catch (error) {
      const err = error as AxiosError;
      if (err.response?.status === 401) {
        setError('Password ou email errados');
      } else {
        setError('Erro no servidor contacte ao administrador do sistema');
      }
    }
    // if (password === '1234' && username !== '') {
    //   dispatch({
    //     type: 'SET_USER',
    //     user: { username: username, password: password, role: 'admin' },
    //   });
    // } else {
    //   setError('* crendeciais incorretas');
    // }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#fff',
      }}
    >
      <form onSubmit={login}>
        <Card>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={logo} alt="" />
          </div>
          <FormGroup>
            <h2 style={{ textAlign: 'center' }}> Portal do Utilizador </h2>
          </FormGroup>
          <FormGroup>
            <label htmlFor="">Username</label>
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="">Password</label>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <span className="error">{error}</span>
          </FormGroup>

          <FormGroup>
            <button
              className="button button-primary"
              style={{ width: '90%' }}
              onClick={(e) => login}
            >
              login
            </button>
          </FormGroup>
        </Card>
      </form>
    </div>
  );
}
