import React from 'react';

import Home from './pages/Home';
import Login from './pages/Login';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import store from './store';
import Main from './components/Main';
import Defendants from './pages/Defendants/Defendant';

import Users from './pages/User';
import NewDefendant from './pages/Defendants/New';
import UpdateDefendant from './pages/Defendants/Update';

import BCI from './pages/banks/bci';

import CorruptionCases from './pages/CorruptionCases/CorruptionCases';
import NewCorruptionCases from './pages/CorruptionCases/New';
import UpdateOccurrence from './pages/CorruptionCases/Update';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import PublicDashboard from './pages/Public/PublicDashboard';
import CorruptionCaseDetails from './pages/Public/CorruptionCaseDetails';
import List from './pages/Public/List';
import DefendantDetails from './pages/Public/DefendantDetails';
import Welcome from './pages/Public/Welcome';
import Courts from './pages/Courts/Courts';
import NewCourt from './pages/Courts/New';
function App() {
  localStorage.setItem('confirmed', 'no');

  console.log('version 1.0.6');

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/bci" element={<BCI />} />
            {/* <Route index element={<Login />} /> */}
            <Route path="/login" element={<Login />} />

            <Route path="/public" element={<PublicDashboard />}>
              <Route
                path="corruption-case/:id"
                element={<CorruptionCaseDetails />}
              />
              <Route path="defendant/:id" element={<DefendantDetails />} />

              <Route path="" element={<List />} />
              <Route path="dashboard" element={<Home />} />
            </Route>
            <Route path="/" element={<Welcome />}></Route>

            <Route element={<Main />}>
              <Route path="/home" element={<Home />} />

              <Route path="defendants" element={<Defendants />}></Route>
              <Route path="defendants/new" element={<NewDefendant />}></Route>
              <Route
                path="defendants/:id"
                element={<UpdateDefendant />}
              ></Route>

              <Route path="/courts" element={<Courts />}></Route>

              <Route path="/courts/new" element={<NewCourt />}></Route>

              <Route
                path="corruption-cases"
                element={<CorruptionCases />}
              ></Route>
              <Route
                path="corruption-cases/new"
                element={<NewCorruptionCases />}
              ></Route>
              <Route
                path="corruption-cases/:id"
                element={<UpdateOccurrence />}
              ></Route>
              <Route
                path="corruption-cases/update/:id"
                element={<NewCorruptionCases />}
              ></Route>

              <Route path="users" element={<Users />}></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
