import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card';
import FormGroup from '../../components/FormGroup';
import PageTitle from '../../components/PageTitle';
import { Reducers } from '../../store/reducers';
import { provinces } from '../../types';
import { Counter } from '../../store/reducers/countersReducer';
import {
  ICorruptionCase,
  ICorruptionCaseDTO,
} from '../../types/ICorruptionCase';
import axios from 'axios';
import Select, { MultiValue } from 'react-select';

import { IDefendant } from '../../types/IDefendant';
import { ICourt } from '../../types/ICourt';
// import { Container } from './styles';

const NewCourt: React.FC = () => {
  const API_URL = useSelector((state: Reducers) => state.util.apiUrl);
  const PATH = 'court';
  const storedDefendants = useSelector(
    (state: Reducers) => state.util.defendants
  );
  const [defendantList, setDefendantList] = useState<
    { label: string; value: string }[]
  >(
    storedDefendants.map((defendant) => {
      return {
        label: defendant.first_name + ' ' + defendant.last_name,
        value: defendant.id,
      };
    })
  );

  const [court, setCourt] = useState<ICourt>({
    name: '',
    court_code: '',
    description: '',
    province: 'MAPUTO_CIDADE',
    id: 0
  });

  const navigate = useNavigate();

  const create = async () => {
    console.log(court);
    let data = { ...court };
    try {
      console.log(data);
      const result = await axios.post(`${API_URL}/${PATH}`, data);
      console.log(result.data);
      navigate('/courts');
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <PageTitle>Novo Tribunal</PageTitle>
      <Card>
        <FormGroup>
          <label htmlFor="">Código do Tribunal</label>
          <input
            type="text"
            placeholder="Código"
            value={court.court_code}
            onChange={(e) => {
              setCourt({
                ...court,
                court_code: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Nome do Tribunal</label>
          <input
            type="text"
            placeholder="Tribunal"
            value={court.name}
            onChange={(e) => {
              setCourt({
                ...court,
                name: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="">Província</label>
          <select
            name=""
            id=""
            defaultValue={''}
            onChange={(e) => {
              setCourt({
                ...court,
                province: e.target.value,
              });
            }}
          >
            <option value="" disabled defaultValue={''}>
              ---- Selecione a Província ----
            </option>
            {provinces.map((province) => (
              <option key={province.value} value={province.value}>
                {' '}
                {province.name}
              </option>
            ))}
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="">Descrição</label>
          <input
            type="text"
            placeholder="(Opcional)"
            value={court.description + ''}
            onChange={(e) => {
              setCourt({
                ...court,
                description: e.target.value,
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <div className="row">
            <button className="button button-success" onClick={create}>
              Salvar
            </button>
            <button
              className="button button-danger"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </button>
          </div>
        </FormGroup>
      </Card>
    </>
  );
};

export default NewCourt;
