import React from 'react';

const Row: React.FC = ({ children }) => {
    return (
        <div className='row' >
            {children}
        </div>
    );
}

export default Row;